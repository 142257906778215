<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar previous-page-label="Tutti Market Top" title="Parameter Sets">
                <template #contents>
                </template>
            </page-toolbar>
        </v-col>

        <v-col md="11" cols="12">
            <v-card>
                <v-data-table
                    sort-desc
                    show-expand
                    :headers="headers"
                    :items="items"
                    sort-by="_created_at"
                    :search="searchQuery"
                >
                    <template v-slot:top>
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-text-field
                                single-line
                                hide-details 
                                v-model="searchQuery"
                                append-icon="mdi-magnify"
                                label="Search" />
                        </v-card-title>
                    </template>

                    <template #item._created_at="{ item }">
                        {{ standardDateTimeFormat(item._created_at) }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-2">
                            Platform Parameter Set Information:
                            <v-simple-table dense v-if="item.platform_parameter_set">
                                <tbody>
                                    <tr v-for="[col, val] in Object.entries(item.platform_parameter_set)" :key="`pps-item-${col}`">
                                        <th>{{ col }}</th>
                                        <td v-if="col==='_created_at'">{{ standardDateTimeFormat(val) }}</td>
                                        <td v-else>{{ val }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import { standardDateTimeFormat } from '@/lib/utils'

export default {
    components: {
        PageToolbar,
    },
    data: () => ({
        searchQuery: '',
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Automation Parameter Set ID', value: 'id' },
            { text: 'Project Name', value: 'project_name' },
            { text: 'Creation Time', value: '_created_at' },
            { text: '', value: 'data-table-expand' },
        ],
        items: [],
    }),
    props: ['projectName', 'market', 'client'],
    methods: {
        standardDateTimeFormat
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            let automationParameterSets = await this.client.resource.listAutomationParameterSets();
            automationParameterSets.forEach(async (aps, i) => {
                automationParameterSets[i].platform_parameter_set = await this.client.resource.getPlatformParameterSet({ platform_parameter_set_id: aps.platform_parameter_set_id });
            });
            this.items = automationParameterSets;
            console.log(this.items);
        });
    }
}
</script>
