import dateFormat from 'dateformat'

export const secondsToFormattedTime = function(seconds, suffixes){
        const _process = (divideBy) => {
            const t = Math.floor(seconds/divideBy);
            seconds %= divideBy;
            time.push(t);
        };
    
        let time = [];
        if(suffixes.length>6) throw 'set suffixes length between 1 to 6'
        if(suffixes.length==6) _process(Math.pow(60, 3)*24*30);
        if(suffixes.length>=5) _process(Math.pow(60, 3)*24);
        for(let i=Math.min(suffixes.length, 4)-1; i>=0; i--) _process(Math.pow(60, i));
        return time.flatMap((t,i) => (t>0 ? `${t} ${suffixes[i]}` : [])).join(' ');
    }

export const standardDateFormat = function(unixTime) {
        return dateFormat(unixTime*1000, 'yyyy-mm-dd');
    }

export const standardTimeFormat = function(unixTime) {
        return dateFormat(unixTime*1000, 'HH:MM:ss');
    }

export const standardDateTimeFormat = function(unixTime) {
        return dateFormat(unixTime*1000, 'yyyy-mm-dd HH:MM:ss');
    }

export const isSameDate = function(unixTime1, unixTime2) {
        return standardDateFormat(unixTime1)===standardDateFormat(unixTime2);
    }

export const generateRandomString = function(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

export const openUrlInNewTab = function(url) {
        window.open(url, '_blank');
    }

export const isObject = function(variable) {
    return typeof variable === 'object' && !Array.isArray(variable) && variable !== null;
}

export const getFileExtension = function(name = '') {
    if(!name || name.split('.').length===1) return null;
    return name.split('.').slice(-1)[0];
}