var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: {
              "previous-page-label": "Tutti Market Top",
              title: "Parameter Sets",
            },
            scopedSlots: _vm._u([
              {
                key: "contents",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "11", cols: "12" } },
        [
          _c(
            "v-card",
            [
              _c("v-data-table", {
                attrs: {
                  "sort-desc": "",
                  "show-expand": "",
                  headers: _vm.headers,
                  items: _vm.items,
                  "sort-by": "_created_at",
                  search: _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-card-title",
                          [
                            _c("v-spacer"),
                            _c("v-text-field", {
                              attrs: {
                                "single-line": "",
                                "hide-details": "",
                                "append-icon": "mdi-magnify",
                                label: "Search",
                              },
                              model: {
                                value: _vm.searchQuery,
                                callback: function ($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item._created_at",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.standardDateTimeFormat(item._created_at)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "expanded-item",
                    fn: function ({ headers, item }) {
                      return [
                        _c(
                          "td",
                          {
                            staticClass: "pa-2",
                            attrs: { colspan: headers.length },
                          },
                          [
                            _vm._v(" Platform Parameter Set Information: "),
                            item.platform_parameter_set
                              ? _c("v-simple-table", { attrs: { dense: "" } }, [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      Object.entries(
                                        item.platform_parameter_set
                                      ),
                                      function ([col, val]) {
                                        return _c(
                                          "tr",
                                          { key: `pps-item-${col}` },
                                          [
                                            _c("th", [_vm._v(_vm._s(col))]),
                                            col === "_created_at"
                                              ? _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.standardDateTimeFormat(
                                                        val
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("td", [_vm._v(_vm._s(val))]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }